import { PingCtrl } from './ping';
import { LangsCtrl } from './langs';
import { SoundCtrl } from './sound';
import { BackgroundCtrl } from './background';
import { BoardCtrl } from './board';
import { ThemeCtrl } from './theme';
import { PieceCtrl } from './piece';
import { prop } from 'common';
const defaultMode = 'links';
export default class DasherCtrl {
    constructor(data, redraw) {
        this.data = data;
        this.redraw = redraw;
        this.opts = {
            playing: $('body').hasClass('playing'),
            zenable: $('body').hasClass('zenable'),
        };
        this.mode = prop(defaultMode);
        this.setMode = (m) => {
            this.mode(m);
            this.redraw();
        };
        this.close = () => this.setMode(defaultMode);
        this.trans = lichess.trans(data.i18n);
        this.ping = new PingCtrl(this.trans, this.redraw);
        const dimension = () => (this.data.board.is3d ? 'd3' : 'd2');
        this.langs = new LangsCtrl(this.data.lang, this.trans, this.close);
        this.sound = new SoundCtrl(this.data.sound.list, this.trans, this.redraw, this.close);
        this.background = new BackgroundCtrl(this.data.background, this.trans, this.redraw, this.close);
        this.board = new BoardCtrl(this.data.board, this.trans, this.redraw, this.close);
        this.theme = new ThemeCtrl(this.data.theme, this.trans, dimension, this.redraw, this.close);
        this.piece = new PieceCtrl(this.data.piece, this.trans, dimension, this.redraw, this.close);
        lichess.pubsub.on('top.toggle.user_tag', () => this.setMode(defaultMode));
    }
}

import { h } from 'snabbdom';
import * as xhr from 'common/xhr';
import { header } from './util';
import { bind } from 'common/snabbdom';
export class ThemeCtrl {
    constructor(data, trans, dimension, redraw, close) {
        this.data = data;
        this.trans = trans;
        this.dimension = dimension;
        this.redraw = redraw;
        this.close = close;
        this.dimensionData = () => this.data[this.dimension()];
        this.set = (t) => {
            const d = this.dimensionData();
            d.current = t;
            applyTheme(t, d.list, this.dimension() === 'd3');
            const field = `theme${this.dimension() === 'd3' ? '3d' : ''}`;
            xhr
                .text(`/pref/${field}`, { body: xhr.form({ [field]: t }), method: 'post' })
                .catch(() => lichess.announce({ msg: 'Failed to save theme preference' }));
            this.redraw();
        };
    }
}
export function view(ctrl) {
    const d = ctrl.dimensionData();
    return h('div.sub.theme.' + ctrl.dimension(), [
        header(ctrl.trans.noarg('boardTheme'), () => ctrl.close()),
        h('div.list', d.list.map(themeView(d.current, ctrl.set))),
    ]);
}
const themeView = (current, set) => (t) => h('button', {
    hook: bind('click', () => set(t)),
    attrs: { title: t, type: 'button' },
    class: { active: current === t },
}, h('span.' + t));
function applyTheme(t, list, is3d) {
    $('body').removeClass(list.join(' ')).addClass(t);
    if (!is3d)
        document.body.dataset.boardTheme = t;
    lichess.pubsub.emit('theme.change');
}

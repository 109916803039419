import { h } from 'snabbdom';
import * as licon from 'common/licon';
import { header } from './util';
export class LangsCtrl {
    constructor(data, trans, close) {
        this.data = data;
        this.trans = trans;
        this.close = close;
        this.list = () => [...this.data.list.filter(lang => this.accepted.has(lang[0])), ...this.data.list];
        this.accepted = new Set(data.accepted);
    }
}
export const view = (ctrl) => h('div.sub.langs', [
    header(ctrl.trans.noarg('language'), ctrl.close),
    h('form', { attrs: { method: 'post', action: '/translation/select' } }, ctrl.list().map(langView(ctrl.data.current, ctrl.accepted))),
    h('a.help.text', { attrs: { href: 'https://crowdin.com/project/lichess', 'data-icon': licon.Heart } }, 'Help translate Lichess'),
]);
const langView = (current, accepted) => ([code, name]) => h('button' + (current === code ? '.current' : '') + (accepted.has(code) ? '.accepted' : ''), { attrs: { type: 'submit', name: 'lang', value: code, title: code } }, name);

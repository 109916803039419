import DasherCtrl from './dasher';
import { loading, loaded } from './view';
import * as xhr from 'common/xhr';
import { init as initSnabbdom, classModule, attributesModule } from 'snabbdom';
const patch = initSnabbdom([classModule, attributesModule]);
export function load() {
    return lichess.asset.loadEsm('dasher');
}
export async function initModule() {
    let vnode, ctrl = undefined;
    const $el = $('#dasher_app').html(`<div class="initiating">${lichess.spinnerHtml}</div>`);
    const element = $el.empty()[0];
    const toggle = $('#top .dasher')[0];
    const redraw = () => {
        vnode = patch(vnode || element, ctrl ? loaded(ctrl) : loading());
    };
    redraw();
    const data = await xhr.json('/dasher');
    ctrl = new DasherCtrl(data, redraw);
    redraw();
    new MutationObserver(_ => lichess.pubsub.emit('dasher.toggle', toggle.classList.contains('shown'))).observe(toggle, {
        attributes: true,
    });
    return ctrl;
}
